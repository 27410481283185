import React, { useEffect } from 'react'
import clsx from 'clsx'

import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import * as styles from './animatedImage.module.scss'

interface AnimatedImageProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  src: string
  alt: string
  initialAnimation: `visible` | `hidden`
}

const boxVariant = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: { type: `spring`, duration: 0.5, delay: 0.2 },
  },
  hidden: { opacity: 0, scale: 0.7 },
}

export const AnimatedImage: React.FC<AnimatedImageProps> = ({
  src,
  alt,
  initialAnimation,
  ...props
}) => {
  const control = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      control.start('visible')
    } else {
      control.start('hidden')
    }
  }, [control, inView])

  return (
    <motion.div
      ref={ref}
      variants={boxVariant}
      initial={initialAnimation}
      animate={control}
      className={clsx(props.className, styles.animatedImage)}
    >
      <img src={src} alt={alt} />
    </motion.div>
  )
}
