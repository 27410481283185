import clsx from 'clsx'
import { motion, Variants } from 'framer-motion'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React, { useCallback, useEffect, useRef } from 'react'
import { useBoolean, useInput } from 'react-hanger'
import toast from 'react-hot-toast'
import { CgSpinner } from 'react-icons/cg'
import { FiArrowDown, FiArrowUp, FiBriefcase, FiMail, FiPhone, FiSend, FiUser, FiUsers } from 'react-icons/fi'
import InputMask from 'react-input-mask'

import contactHero from '../../assets/banner-contact-hero.webp'
import aboutImage from '../../assets/partners/partnerFeatures.png'
import mainImage from '../../assets/partners/partnerHeader.png'
import logo from '../../assets/roberty.png'
import designerDemo from '../../assets/studio/designer-header.mp4'
import { AnimatedImage } from '../../components/animatedImage'
import { Button } from '../../components/button'
import { actionList, actionListType } from '../../components/contactModal/actions'
import { LinkCustom } from '../../components/contactModal/contactActions'
import { FeaturesGridIcon } from '../../components/featuresGridIcon'
import { Layout } from '../../components/layout'
import { SectionTitle } from '../../components/sectionTItle'
import { PartnerList } from '../../components/partners'
import i18n from '../../i18n'
import * as styles from '../../styles/pages/partner.module.scss'
import { api } from '../../utils/api'
import { useMediaQuery } from '../../utils/hooks'
import { SEO } from '../../components/SEO'

export const enterDiv: Variants = {
  visible: {
    opacity: 1,
    left: 0,
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 0.8,
      delay: 0.2,
    },
  },
  hidden: { opacity: 0, left: -300 },
}

const enterDivInvert: Variants = {
  visible: {
    opacity: 1,
    right: 0,
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 0.8,
    },
  },
  hidden: { opacity: 0, right: -300 },
}

const listItemVariants: Variants = {
  open: (i) => ({
    y: 0,
    opacity: 1,
    transition: {
      y: { delay: i * 0.2 },
      duration: 0.5,
      type: 'spring',
    },
  }),
  closed: {
    y: 40,
    opacity: 0,
  },
}

const listItemVariantsProfits: Variants = {
  open: (i) => ({
    y: 0,
    opacity: 1,
    transition: {
      y: { delay: i * 0.2 },
      duration: 0.5,
      type: 'spring',
    },
  }),
  closed: {
    y: 40,
    opacity: 0,
  },
}

const Partners: React.FC = () => {
  const mainVideo = useRef<HTMLVideoElement>(null)
  const isMobile = useMediaQuery(`580`)
  const isInSale = !!process.env.GATSBY_IS_ON_SALE

  const features = [
    `${i18n[`common.unlimitedLicenses`]}`,
    `${i18n[`common.designerSoftware`]}`,
    `${i18n[`common.unlimitedBots`]}`,
    `${i18n[`common.supportConsulting`]}`,
    `${i18n[`common.referralsLeads`]}`,
  ]

  const profits = [
    `${i18n[`common.upgrades`]}`,
    `${i18n[`common.values`]}`,
    `${i18n[`common.maintenance`]}`,
  ]

  useEffect(() => {
    const video = mainVideo.current

    if (video) {
      setTimeout(() => {
        video.play()
        // enable video controls for mobile
        isMobile && video.setAttribute(`controls`, `true`)
      }, 500)
    }

    return () => {
      if (video) {
        video?.pause()
        video.currentTime = 0
      }
    }
  }, [mainVideo, isMobile])

  const loading = useBoolean(false)
  const name = useInput(``)
  const email = useInput(``)
  const phone = useInput(``)
  const businessName = useInput(``)
  const messageText = useInput(``)
  const businessSize = useInput(``)

  const sizeList = [
    { id: `1-19`, name: `1-19` },
    { id: `20-49`, name: `20-49` },
    { id: `50-99`, name: `50-99` },
    { id: `100-250`, name: `100-250` },
    { id: `251-1000`, name: `251-1000` },
    { id: `1000+`, name: `1000+` },
  ]

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      loading.setTrue()
      try {
        await api.post(`1/website/contactForm`, {
          title: `[Formulário parceiro]`,
          name: name.value,
          email: email.value,
          phone: phone.value,
          businessName: businessName.value,
          businessSize: businessSize.value,
          message: messageText.value,
        })
        toast.success(
          `${i18n[`common.allRight`]}`
        )
        name.clear()
        email.clear()
        phone.clear()
        businessName.clear()
        businessSize.clear()
        messageText.clear()
      } catch (error) {
        toast.error(
          `${i18n[`common.errorTryAgain`]}`
        )
        console.error(error)
      } finally {
        loading.setFalse()
      }
    },
    [loading, name, email, phone, businessName, businessSize, messageText]
  )

  return (
    <Layout isLargeFooter={false} whiteHeader={false} isHome={false}>
      <SEO title="Seja um parceiro - Roberty Automation" />
      <div className={styles.partner}>
        <section
          className={clsx(styles.initialWrapper, {
            [styles.hasSales]: isInSale,
          })}
        >
          <div className="container-fluid">
            <div className="row flex-column-reverse flex-sm-row d-flex align-items-center">
              <motion.div
                variants={enterDiv}
                initial="hidden"
                whileInView="visible"
                className="col-12 co-sm-12 col-lg-6"
              >
                <div className={styles.title}>
                  {!isMobile && (
                    <h1>
                      {i18n[`common.beRoberty`]} <br /> {i18n[`common.robertyPartner`]}
                    </h1>
                  )}
                  <p>
                    {i18n[`common.beImplanter`]}
                  </p>
                  <strong>
                    {i18n[`common.costBenefit`]}
                  </strong>
                </div>
                <AnchorLink to={`#contact`}>
                  <button type="button">{i18n[`common.partner`]}</button>
                </AnchorLink>
              </motion.div>
              <div className="col-12 co-sm-12 col-lg-6">
                {isMobile && (
                  <motion.h1
                    variants={enterDiv}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    className={styles.mobileTitle}
                  >
                    {i18n[`common.beRoberty`]}{i18n[`common.robertyPartner`]}
                  </motion.h1>
                )}

                <AnimatedImage
                  className={styles.image}
                  src={mainImage}
                  alt="Roberty Partner"
                  initialAnimation="visible"
                />
              </div>
            </div>
          </div>
        </section>

        <section className={styles.about}>
          <div className="container">
            <div className="row d-flex align-items-center">
              <motion.div
                variants={enterDivInvert}
                initial="hidden"
                whileInView="visible"
                className="col-12 co-sm-12 col-lg-12"
              >
                <div className={styles.title}>
                  <h1>{i18n[`common.businessModel`]}</h1>
                  <p>
                    {i18n[`common.robertyDealer`]}{' '}
                    <strong>{i18n[`common.recipe`]}</strong>
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        <section className={styles.businessModel}>
          <div className="container">
            <div className="row justify-content-center">
              <div
                className={clsx(
                  `col-12 d-flex align-items-center justify-content-center`,
                  styles.logoContent
                )}
              >
                <span>Software</span>
                <div className={styles.roberty}>
                  <img src={logo} alt="Roberty" />
                </div>
                <span>{i18n[`common.supportPlan`]}</span>
              </div>

              <motion.div
                variants={enterDivInvert}
                initial="hidden"
                whileInView="visible"
                className={clsx(
                  `col-12 d-flex align-items-center justify-content-center`,
                  styles.arrows
                )}
              >
                <div>
                  <FiArrowUp />
                  <span>{i18n[`common.investiment`]}</span>
                </div>

                <div>
                  <span>{i18n[`common.technologySupport`]}</span>
                  <FiArrowDown />
                </div>
              </motion.div>

              <div
                className={clsx(
                  `col-12 col-md-8 d-flex align-items-center justify-content-center`,
                  styles.client
                )}
              >
                <span>{i18n[`common.partnerRoberty`]}</span>
                <small>{i18n[`common.serviceProvider`]}</small>
              </div>

              <motion.div
                variants={enterDiv}
                initial="hidden"
                whileInView="visible"
                className={clsx(
                  `col-12 d-flex align-items-center justify-content-center`,
                  styles.arrows,
                  styles.arrowsInvert
                )}
              >
                <div>
                  <FiArrowUp />
                  <span>{i18n[`common.recurringRevenue`]}</span>
                </div>

                <div>
                  <span>{i18n[`common.useRPA`]}</span>
                  <FiArrowDown />
                </div>
              </motion.div>

              <div
                className={clsx(
                  `col-12 col-md-8 d-flex align-items-center justify-content-center`,
                  styles.customer
                )}
              >
                <span>{i18n[`common.robertyClient`]}</span>
              </div>
            </div>
          </div>
        </section>

        <section>
          <PartnerList />
        </section>

        <section className={styles.features}>
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-12 col-sm-12 col-lg-6">
                <span>{i18n[`common.services`]}</span>
                <motion.ul className={styles.featuresList}>
                  {features.map((feature, index) => (
                    <motion.li
                      custom={index}
                      whileInView="open"
                      initial="closed"
                      variants={listItemVariants}
                      key={index}
                      viewport={{ once: true, amount: 0.8 }}
                    >
                      {feature}.
                    </motion.li>
                  ))}
                </motion.ul>
                <span className={styles.secondTitle}>
                  {i18n[`common.recipeCliente`]}
                </span>
                <motion.ul className={styles.featuresList}>
                  {profits.map((feature, index) => (
                    <motion.li
                      custom={index}
                      whileInView="open"
                      initial="closed"
                      variants={listItemVariantsProfits}
                      key={index}
                      viewport={{ once: true, amount: 0.8 }}
                    >
                      {feature}.
                    </motion.li>
                  ))}
                </motion.ul>
                <AnchorLink to={`#contact`}>
                  <button type="button">{i18n[`common.partner`]}</button>
                </AnchorLink>
              </div>

              {!isMobile && (
                <div className="col-12 co-sm-12 col-lg-6">
                  <AnimatedImage
                    className={styles.image}
                    src={aboutImage}
                    alt="Roberty Partner"
                    initialAnimation="hidden"
                  />
                </div>
              )}
            </div>
          </div>
        </section>

        <section className={styles.showcase}>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-sm-12 col-lg-10">
                <SectionTitle
                  title={<h1>{i18n[`common.whyRoberty`]}</h1>}
                  position="center"
                  color="white"
                  description={i18n[`common.useRobertyReason`]}
                />
              </div>
              <div className="col-12 p-2 p-md-4 p-xl-5">
                <FeaturesGridIcon />
              </div>
              <div className="col-12 col-sm-12 col-lg-10 mt-5 mt-sm-0">
                <SectionTitle
                  title={<h1>{i18n[`common.robertyAlly`]}</h1>}
                  position="center"
                  color="white"
                  description={
                    <>
                      <span>
                        {i18n[`common.robertyDescription`]}
                      </span>
                      <br />
                      <br />
                      <span>
                        {i18n[`common.robertyNotExperience`]}
                      </span>
                    </>
                  }
                />
              </div>
              <div className="col-12 col-sm-12 col-lg-10">
                <div className={styles.gif}>
                  <video
                    controls={false}
                    loop={true}
                    muted={true}
                    ref={mainVideo}
                  >
                    <source
                      src={`${designerDemo}#t=0.001`}
                      title={i18n[`common.demonstration`]}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.contact} id="contact">
          <div className={styles.imageContact}>
            <img src={contactHero} />
          </div>
          <div className={clsx('container', styles.contactItems)}>
            <div className="row d-flex justify-content-sm-center">
              {actionList.map((item: actionListType, index) => (
                <div className="col-12 col-sm-6 col-lg-4" key={index}>
                  <LinkCustom
                    item={item}
                    isFunction={item.actionLink ? false : true}
                  >
                    <div
                      className={clsx(styles.actionItem, {
                        [styles.call]: item.type === 'call',
                        [styles.schedule]: item.type === 'schedule',
                        [styles.whats]: item.type === 'whats',
                      })}
                    >
                      <div className={styles.actionIcon}>{item.icon}</div>
                      <div className={styles.actionContent}>
                        <span>{item.actionText}</span>
                      </div>
                    </div>
                  </LinkCustom>
                </div>
              ))}
            </div>
          </div>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className={clsx('col-12 col-sm-10 col-lg-8', styles.title)}>
                <h1>
                  {i18n[`common.become`]}<br /> {i18n[`common.partnerRoberty`]}
                </h1>
                <p>
                  {i18n[`common.fillFields`]}
                </p>
              </div>
              <div className="col-12 col-sm-10 col-lg-8 col-xl-6">
                <form onSubmitCapture={handleSubmit} id="BecomePartnerForm">
                  <div className="input-group mb-3">
                    <div className="prepend-icon">
                      <FiUser />
                    </div>
                    <input
                      required={true}
                      name="name"
                      type="text"
                      placeholder={i18n[`common.fullName`]}
                      className="form-control  modalInput"
                      value={name.value}
                      onChange={name.onChange}
                      disabled={loading.value}
                    />
                  </div>

                  <div className="input-group mb-3">
                    <div className="prepend-icon">
                      <FiMail />
                    </div>
                    <input
                      required={true}
                      name="email"
                      type="email"
                      placeholder="E-mail"
                      className="form-control  modalInput"
                      value={email.value}
                      onChange={email.onChange}
                      disabled={loading.value}
                    />
                  </div>

                  <div className="input-group mb-3">
                    <div className="prepend-icon">
                      <FiPhone />
                    </div>
                    <InputMask
                      mask="(99) 99999-9999"
                      required={true}
                      name="phone"
                      type="text"
                      placeholder={i18n[`common.phone`]}
                      className="form-control modalInput"
                      value={phone.value}
                      onChange={phone.onChange}
                      disabled={loading.value}
                    />
                  </div>

                  <div className="input-group mb-3">
                    <div className="prepend-icon">
                      <FiBriefcase />
                    </div>
                    <input
                      required={true}
                      name="businessName"
                      type="text"
                      placeholder={i18n[`common.businessName`]}
                      className="form-control modalInput"
                      value={businessName.value}
                      onChange={businessName.onChange}
                      disabled={loading.value}
                    />
                  </div>

                  <div className="input-group mb-3">
                    <div className="prepend-icon">
                      <FiUsers />
                    </div>
                    <select
                      name="businessSize"
                      className="form-control modalInput"
                      onChange={businessSize.onChange}
                      disabled={loading.value}
                      value={businessSize.value}
                      required={true}
                    >
                      <option disabled value={``}>
                        {i18n[`common.businessSize`]}
                      </option>
                      {sizeList.map((item, key) => (
                        <option key={key} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="input-group ">
                    <textarea
                      required={true}
                      placeholder={i18n[`common.messageText`]}
                      name="messageText"
                      className="form-control modalInput"
                      value={messageText.value}
                      onChange={messageText.onChange}
                      disabled={loading.value}
                      rows={5}
                    ></textarea>
                  </div>
                  <div className={styles.buttonAction}>
                    <p className={styles.privacy}>
                      {i18n[`common.sendInfo`]}
                      {` `}
                      <a href="/privacyPolicy">
                        {i18n[`common.privacyPolicy`]}
                      </a>
                      .
                    </p>
                    <Button
                      color="primary"
                      className="mt-4"
                      type="submit"
                      icon={
                        loading.value ? (
                          <CgSpinner className="icon-spin" size={18} />
                        ) : (
                          <FiSend size={18} />
                        )
                      }
                      disabled={loading.value}
                      iconPosition="right"
                    >
                      {loading.value ? `${i18n[`common.sendingContact`]}` : `${i18n[`common.sendContact`]}`}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Partners
