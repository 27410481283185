// extracted by mini-css-extract-plugin
export var about = "partner-module--about--YLOOW";
export var actionContent = "partner-module--actionContent--yprSQ";
export var actionIcon = "partner-module--actionIcon--qDOBn";
export var actionItem = "partner-module--actionItem--zcVDW";
export var arrows = "partner-module--arrows--3c0mX";
export var arrowsInvert = "partner-module--arrowsInvert--1-8yS";
export var blueGradient = "partner-module--blue-gradient--SiAkh";
export var blueGradientCitric = "partner-module--blue-gradient-citric--V0j4Z";
export var businessModel = "partner-module--businessModel--ATE4t";
export var buttonAction = "partner-module--buttonAction--8tQvl";
export var call = "partner-module--call--lu+bJ";
export var client = "partner-module--client--pu5C1";
export var contact = "partner-module--contact--93Jww";
export var contactItems = "partner-module--contactItems--EPzPT";
export var customer = "partner-module--customer--q2wR9";
export var darkBlueGradient = "partner-module--dark-blue-gradient--Bp-VZ";
export var darkGradient = "partner-module--dark-gradient--ld50J";
export var features = "partner-module--features--vBI2a";
export var featuresList = "partner-module--featuresList--UsVtq";
export var gif = "partner-module--gif--CVXpN";
export var hasSales = "partner-module--hasSales--x9Rvd";
export var image = "partner-module--image--KlVb6";
export var imageContact = "partner-module--imageContact--37BTL";
export var initialWrapper = "partner-module--initialWrapper--InOw1";
export var lightGradient = "partner-module--light-gradient--Y+WvC";
export var logoContent = "partner-module--logoContent--xqUGN";
export var mobileTitle = "partner-module--mobileTitle--lXKmM";
export var partner = "partner-module--partner--I6X1P";
export var privacy = "partner-module--privacy--yfmkx";
export var purpleBlueGradient = "partner-module--purple-blue-gradient--YAxQ2";
export var roberty = "partner-module--roberty--E9WQt";
export var rocket = "partner-module--rocket--f2HJA";
export var schedule = "partner-module--schedule--6Qtul";
export var secondTitle = "partner-module--secondTitle--BQ-op";
export var showcase = "partner-module--showcase--0FtMd";
export var softBlueGradient = "partner-module--soft-blue-gradient--kzuYv";
export var softGreyGradient = "partner-module--soft-grey-gradient--CzKmd";
export var title = "partner-module--title--4tkfI";
export var whats = "partner-module--whats--G-Nvu";